import { createRouter, createWebHistory } from "vue-router";

import routes from "./routes";

const mode = import.meta.env.MODE;

const router = createRouter({
  history: createWebHistory(mode === "development" ? "souscription" : "/"),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;

<template>
  <RouterView />
  <AppGoTop />
  <AppLoaderOverlay v-if="showLoaderOverlay" />
</template>

<script>
import "bootstrap/js/dist/collapse";
import "@popperjs/core/dist/umd/popper.min.js";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/modal";
import AppGoTop from "@/vue/components/UI/AppGoTop";
import AppLoaderOverlay from "@/vue/components/UI/AppLoaderOverlay";

import useBreakpoints from "@/hooks/breakpoints";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  components: { AppGoTop, AppLoaderOverlay },
  setup() {
    useBreakpoints();

    const store = useStore();
    const showLoaderOverlay = computed(() => store.getters["general/getShowLoaderOverlay"]);

    return { showLoaderOverlay };
  },
};
</script>

<style lang="scss">
@import "bootstrap/scss/bootstrap";
$bootstrap-icons-font-src: url("bootstrap-icons/font/fonts/bootstrap-icons.woff2") format("woff2"),
  url("bootstrap-icons/font/fonts/bootstrap-icons.woff") format("woff");
@import "bootstrap-icons/font/bootstrap-icons";
@import "@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss";
</style>

import { commonGlobalRoutes } from "@/utils/global.router";

export default [
  ...commonGlobalRoutes(),
  {
    path: "/:dossierAccessHash?",
    name: "route.subscription",
    component: () => {
      return import(
        /* webpackChunkName: "subscription" */ "@/vue/views/subscription/SubscriptionView.vue"
      );
    },
  },
  {
    path: "/merci/:dossierOfferSetAccessHash?",
    name: "route.subscription.success",
    component: () => {
      return import(
        /* webpackChunkName: "subscription" */ "@/vue/views/subscription/SubscriptionSuccessView.vue"
      );
    },
  },
  {
    path: "/devis/:accessHash",
    name: "route.subscription.devis",
    component: function () {
      return import(
        /* webpackChunkName: "subscription" */ "@/vue/views/subscription/SubscriptionDevisView.vue"
      );
    },
  },
  {
    path: "/demande-offre/:accessHash",
    name: "route.subscription.request",
    component: function () {
      return import(
        /* webpackChunkName: "subscription" */ "@/vue/views/subscription/SubscriptionDevisView.vue"
      );
    },
  },
  {
    path: "/offre/:accessHash",
    name: "route.subscription.offer",
    component: function () {
      return import(
        /* webpackChunkName: "subscription" */ "@/vue/views/subscription/SubscriptionOfferView.vue"
      );
    },
  },
  {
    path: "/contactez/:accessHash",
    name: "route.subscription.contact",
    component: function () {
      return import(
        /* webpackChunkName: "subscription" */ "@/vue/views/subscription/ContactOfferView.vue"
      );
    },
  },
  {
    path: "/contactez/merci",
    name: "route.subscription.contact.success",
    component: () => {
      return import(
        /* webpackChunkName: "subscription" */ "@/vue/views/subscription/SubscriptionContactSuccessView.vue"
      );
    },
  },
  {
    path: "/map",
    name: "route.subscription.map",
    component: function () {
      return import(
        /* webpackChunkName: "subscription" */ "@/vue/views/subscription/SubscriptionMapView.vue"
      );
    },
  },
  {
    path: "/:catchAll(.*)",
    redirect: { name: "route.subscription" }, // TODO: redirect to page not found
  },
];
